<template>
  <b-navbar ref="navbar" type="is-white">
    <template slot="brand">
      <b-navbar-item tag="router-link" :to="{ path: '/' }">
        <router-link :to="{ name: 'dashboard' }" class="brand">
          <div class="brand-content">
            <div class="title">{{ brandText }}</div>
            <div class="subtitle is-hidden-touch">{{ subBrandText }}</div>
          </div>
        </router-link>
      </b-navbar-item>
    </template>

    <template slot="end">
      <b-navbar-item
        tag="router-link"
        :to="{ name: 'kegiatan-pspd-list' }"
      >Aktivitas Harian</b-navbar-item>

      <b-navbar-dropdown :collapsible="true" label="Aktivitas Klinis">
        <b-navbar-item
          tag="router-link"
          :to="{ name: 'aktivitas-keterampilan-list' }"
        >Keterampilan Klinis</b-navbar-item>
        <b-navbar-item
          tag="router-link"
          :to="{ name: 'klinis-list' }"
        >Pengelolaan Kasus</b-navbar-item>
        <b-navbar-item
          v-if="isAdmin"
          tag="router-link"
          :to="{ name: 'pasien-list' }"
        >Pasien</b-navbar-item>
      </b-navbar-dropdown>

      <b-navbar-dropdown :collapsible="true" label="Penilaian">
        <b-navbar-item
          tag="router-link"
          :to="{ name: 'karyailmiah-list' }"
        >Kegiatan Ilmiah</b-navbar-item>
        <b-navbar-item
          tag="router-link"
          :to="{ name: 'ujian-list' }"
        >Ujian</b-navbar-item>
      </b-navbar-dropdown>

      <b-navbar-dropdown :collapsible="true" label="Jadwal">
        <b-navbar-item
          tag="router-link"
          :to="{ name: 'jadwal-departemen-list' }"
        >Departemen</b-navbar-item>
        <b-navbar-item
          tag="router-link"
          :to="{name: 'stase-list'}"
        >Stase</b-navbar-item>
      </b-navbar-dropdown>

      <b-navbar-dropdown :collapsible="true" v-if="isAdmin" label="Program Studi">
        <b-navbar-item
          tag="router-link"
          :to="{ name: 'anggota-list' }"
        >Anggota</b-navbar-item>
        <b-navbar-item
          tag="router-link"
          :to="{ name: 'angkatan-list' }"
        >Angkatan</b-navbar-item>
        <b-navbar-item
          tag="router-link"
          :to="{ name: 'kd-list' }"
        >Target Keterampilan</b-navbar-item>
        <b-navbar-item
          tag="router-link"
          :to="{ name: 'diag-list' }"
        >Target Diagnosis</b-navbar-item>
        <b-navbar-item
          v-if="isAdminPusat"
          tag="router-link"
          :to="{ name: 'kelompok-pspd' }"
        >Kelompok</b-navbar-item>
      </b-navbar-dropdown>

      <b-navbar-dropdown v-if="isAdmin" :collapsible="true" label="Pencapaian">
        <b-navbar-item
          tag="router-link"
          :to="{ name: 'pencapaian-pspd' }"
        >Mahasiswa</b-navbar-item>
        <b-navbar-item
          tag="router-link"
          :to="{name: 'pencapaian-nilai'}"
        >Nilai Akhir</b-navbar-item>
      </b-navbar-dropdown>

      <b-navbar-item
        v-else-if="isPreceptor"
        tag="router-link"
        :to="{ name: 'pencapaian-pspd'}"
      >Pencapaian</b-navbar-item>

      <b-navbar-item
        v-else
        tag="router-link"
        :to="{ name: 'pencapaian-pspd-detail', params: {id: userId} }"
      >Pencapaian</b-navbar-item>

      <b-navbar-dropdown :collapsible="true" :label="userName" class="user-nav">
        <b-navbar-item
          tag="router-link"
          :to="{ name: 'anggota-me' }"
        >Profil</b-navbar-item>
        <b-navbar-item
          tag="router-link"
          :to="{ name: 'change-password' }"
        >Ubah Sandi</b-navbar-item>
        <b-navbar-item
          tag="router-link"
          :to="{ name: 'bantuan' }"
        >Bantuan</b-navbar-item>
        <b-navbar-item @click.native.stop="logout">Keluar</b-navbar-item>
      </b-navbar-dropdown>
    </template>
  </b-navbar>
</template>

<script>
import APP_CONFIG from "@/apps/core/modules/config.js";
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  name: "Navbar",
  data() {
    return {
      brandText: APP_CONFIG.brandText,
      subBrandText: APP_CONFIG.subBrandText
    };
  },
  computed: {
    ...mapState("accounts", ["name", "initial", "userId"]),
    ...mapGetters("accounts", [
      "isAdmin",
      "isAdminPusat",
      "isPreceptor",
      "isPSPD"
    ]),
    userName() {
      return this.name;
    }
  },
  methods: {
    ...mapActions("core", { resetAllCoreState: "resetAllState" }),
    ...mapActions("accounts", { resetAllAccountsState: "resetAllState" }),
    logout() {
      this.resetAllCoreState();
      this.resetAllAccountsState();
      this.$router.push({ name: "login" });
    }
  }
};
</script>

<style scoped lang="scss">
div.user-nav {
  /* terkait dengan user-nav-menu visibilty */
  min-width: 9rem;
}

@media screen and (max-width: 1087px) {
  .subtitle {
    display: none;
  }
}

@media screen and (min-width: 1180px) {
  .navbar-item.is-hoverable:hover .navbar-dropdown {
    display: block !important;
  }
  .navbar-item.is-hoverable:focus-within .navbar-dropdown {
    display: none;
  }
}
</style>
