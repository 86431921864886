<template>
  <div v-if="userId" id="mainapp">
    <!-- close navbar menu -->
    <NavBar />
    <main class="section">
      <div class="container">
        <keep-alive :exclude="exclude">
          <router-view></router-view>
        </keep-alive>
      </div>
    </main>
  </div>
</template>

<script>
import NavBar from "@/apps/core/components/NavBar.vue";
import { mapState, mapActions } from "vuex";


export default {
  name: "MainAppLayout",
  components: { NavBar },
  data() {
    return {
      // semua component yang berakhiran Update, Detail,
      // atau Create di-exclude dari keep-alive
      exclude: new RegExp("(.+?)(Update|Detail|Create)")
    };
  },
  computed: {
    ...mapState("accounts", ["userId", "accessToken"])
  },
  methods: {
    ...mapActions("core", ["resetAllState"])
  }
};
</script>

<style scoped lang="scss">
@media screen and (max-width: 1180px) {
  .section {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem;
    padding-left: 0rem;
    padding-right: 0rem;
  }

  .container {
    padding-left: 0rem !important;
    padding-right: 0rem !important;
    padding-bottom: 0.5rem !important;
  }
}

.section {
  padding-top: 1.5rem;
}

div.container {
  padding-bottom: 1.5rem;
}
</style>